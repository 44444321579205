.fade-in {
  animation: fadeIn linear 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.placeholder-item:before {
  content: '';
  display: block;
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, transparent 0%, #d3d3d3 50%, transparent 100%);
  animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}

@keyframes progress {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.invisible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.btn[disabled] {
  opacity: 0.5;
}

#root {
  position: relative;
  z-index: 0;
}

#floating-ui-root {
  position: relative;
  z-index: 1;
}

.custom-scrollbar::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #484848;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-corner,
.custom-scrollbar::-webkit-resizer {
  background: #171717;
}

.bg-ws-panel,
.bg-ws-panel-body {
  background-color: rgb(24 24 27);
}

.border-ws-panel {
  border-color: #ffffff15;
}

.border-ws-panel-100 {
  border-color: #ffffff10;
}

.border-ws-panel-200 {
  border-color: #ffffff24;
}

.border-ws-panel-300 {
  border-color: #ffffff39;
}
