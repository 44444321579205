.container {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.75rem;
  background: #ced4da;
  transition: all 0.2s;
  border-radius: 30px;
  cursor: pointer;
}

.toggle {
  position: absolute;
  visibility: hidden;
}

.inner {
  width: 100%;
  height: 100%;
}

.inner:before {
  content: '';
  display: block;
  background: #ffffff;
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  top: 0.25rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.toggle:checked + .inner:before {
  transform: translateX(100%);
}

.iconLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  bottom:  0.25rem;
}

.iconRight {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  bottom:  0.25rem;
}