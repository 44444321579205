:root {
  --input-switch-width: 28px;
}

.input-switch input {
  position: absolute;
  visibility: hidden;
}

.input-switch {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.input-switch .toggle {
  position: relative;
  width: var(--input-switch-width);
  height: calc(var(--input-switch-width) / 2);
  border-radius: calc(var(--input-switch-width) / 2);
  background: rgb(66, 66, 66);
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  align-items: center;
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.input-switch .toggle:before {
  position: absolute;
  content: '';
  display: block;
  background: #8d8d8d;
  position: absolute;
  width: calc(var(--input-switch-width) / 2);
  height: calc(var(--input-switch-width) / 2);
  border-radius: 50%;
  transition-duration: 0.1s;
  top: 0;
  bottom: 0;
  margin: auto;
  outline: 1px solid rgba(0, 0, 0, 0.1);
}

.input-switch:hover .toggle:before {
  background: #c2c2c2;
}

.input-switch:hover {
  color: #c2c2c2;
}

.input-switch input:checked ~ span {
  color: rgb(96, 165, 250);
}

.input-switch input:checked + .toggle {
  background: rgb(37, 99, 235);
}

.input-switch input:checked + .toggle:before {
  background: #e6e6e6;
  transform: translateX(100%);
}
