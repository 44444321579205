@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
}
